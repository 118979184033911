import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase } from 'firebase/database';
import { FirebaseStorage, getStorage, ref as storageRef, getDownloadURL, getBytes, deleteObject } from 'firebase/storage';
import { doc, collection, Firestore, getFirestore, getDocs, getDoc, runTransaction, deleteDoc } from 'firebase/firestore';
import {
    Auth,
    getAuth,
} from 'firebase/auth';
import { Functions, getFunctions } from 'firebase/functions';
import { ImageLabelData, ImageLabeledData, isTask, isTaskData, RENDER_JOBS_COLLECTION_V1, TaskData } from './types';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDXbOgZ5QO-UwmHsI-B1s6eKTvvvct-uIc",
    authDomain: "ando-label.firebaseapp.com",
    projectId: "ando-label",
    storageBucket: "ando-label.appspot.com",
    messagingSenderId: "180677238976",
    appId: "1:180677238976:web:221b94e53fe28180b94d25",
    measurementId: "G-4HMJ3V6N64"
};

let firebaseApp: FirebaseApp | null = null;
let firebaseAuth: Auth | null = null;
let firebaseDatabase: Database | null = null;
let firebaseStorage: FirebaseStorage | null = null;
let firestore: Firestore | null = null;
let firebaseFunctions: Functions | null = null;

const firebaseRegion = process.env.firebaseRegion || 'us-central1';

export function getFirebaseApp() {
    if (firebaseApp == null || firebaseDatabase == null || firebaseAuth == null || firebaseStorage == null || firebaseFunctions == null) {
        firebaseApp = initializeApp(firebaseConfig);
        firebaseFunctions = getFunctions(firebaseApp, firebaseRegion);
        firebaseDatabase = getDatabase(firebaseApp);
        firebaseAuth = getAuth(firebaseApp);
        firebaseStorage = getStorage(firebaseApp);
        firestore = getFirestore(firebaseApp);
    }
    return {
        firebaseApp,
        firebaseDatabase,
        firebaseAuth,
        firebaseStorage,
        firestore,
        firebaseFunctions,
    };
}

export function getTasksCollectionRef() {
    return firestore && collection(firestore, 'tasks');
}

export function getTaskRef(taskId: string) {
    return firestore && doc(firestore, `tasks/${taskId}`);
}

export function getTaskDataCollectionRef(taskId: string) {
    return firestore && collection(firestore, `tasks/${taskId}/data`);
}

export function getTaskDataRef(taskId: string, dataId: string) {
    return firestore && doc(firestore, `tasks/${taskId}/data/${dataId}`);
}

export function getTaskDataLabelCollectionRef(taskId: string, dataId: string) {
    return firestore && collection(firestore, `tasks/${taskId}/data/${dataId}/labels`);
}

export function getTaskDataLabelRef(taskId: string, dataId: string, labelId: string) {
    return firestore && doc(firestore, `tasks/${taskId}/data/${dataId}/labels/${labelId}`);
}

export function getTaskNames() {
    const tasksCollectionRef = getTasksCollectionRef();
    if (tasksCollectionRef) {
        return getDocs(tasksCollectionRef).then((snapshot) => {
            const taskNames: Record<string, string> = {};
            snapshot.forEach((doc) => {
                taskNames[doc.id] = doc.data()?.name || 'Unknown task';
            });
            return taskNames;
        });
    }
    return Promise.resolve({});
}

export function getTask(taskId: string) {
    const taskRef = getTaskRef(taskId);
    if (!taskRef) {
        return Promise.resolve(null);
    }
    return getDoc(taskRef).then((snapshot) => {
        const task = snapshot.data();
        return isTask(task) && task;
    });
}

export function getTaskDataIds(taskId: string) {
    const taskCollectionsRef = getTaskDataCollectionRef(taskId);
    if (taskCollectionsRef) {
        return getDocs(taskCollectionsRef).then((snapshot) => {
            const taskDataIds: string[] = [];
            snapshot.forEach((doc) => {
                taskDataIds.push(doc.id);
            });
            return taskDataIds;
        });
    }
    return Promise.resolve([]);
}

export function getTaskData(taskId: string, dataId: string) {
    const taskDataRef = getTaskDataRef(taskId, dataId);
    if (taskDataRef) {
        return getDoc(taskDataRef).then(async (snapshot) => {
            const data = snapshot.data();
            if (!data) {
                return undefined;
            }
            const taskDataLabelsRef = getTaskDataLabelCollectionRef(taskId, dataId);
            if (taskDataLabelsRef) {
                const labelsSnapshot = await getDocs(taskDataLabelsRef);
                data.labels = {};
                labelsSnapshot.forEach((labelSnapshot) => {
                    const label = labelSnapshot.data();
                    if (label) {
                        const labelId = labelSnapshot.id;
                        data.labels[labelId] = label;
                    }
                });
            }
            return isTaskData(data) && data;
        });
    }
    return Promise.resolve(null);
}

export function getImageUrlFromPath(path: string) {
    if (!firebaseStorage) {
        return Promise.resolve('');
    }
    return getDownloadURL(storageRef(firebaseStorage, path));
}

export function getImageUrlFromTaskData(taskData?: TaskData) {
    if (!firebaseStorage) {
        return Promise.resolve('');
    }
    const path = taskData?.path;
    if (!path) {
        return Promise.resolve('');
    }
    return getDownloadURL(storageRef(firebaseStorage, path));
}

export function updateTaskDataLabels(
    taskId: string, 
    dataId: string,
    labels: Record<string, ImageLabelData>,
) {
    if (firestore && labels) {
        const taskDataRef = getTaskDataRef(taskId, dataId);
        if (taskDataRef) {
            return runTransaction(firestore, async (transaction) => {
                Object.entries(labels).forEach(([labelId, data]) => {
                    if (data.crop && data.crop.unit && data.crop.width && data.crop.height) {
                        const taskDataLabelRef = getTaskDataLabelRef(taskId, dataId, labelId);
                        if (taskDataLabelRef) {
                            transaction.set(
                                taskDataLabelRef,
                                {
                                    caption: data.caption || "",
                                    crop: data.crop,
                                },
                                {
                                    merge: true,
                                }
                            );
                        }
                    }
                });
            });
        }
    }
    return Promise.resolve();
}

export function getLabeledTaskImagePaths(
    taskId: string,
) {
    /**
     * For each task data, check if it has a valid path.
     * If so, add the task to the path
     */
     const taskCollectionsRef = getTaskDataCollectionRef(taskId);
     if (taskCollectionsRef) {
         return getDocs(taskCollectionsRef).then(async (snapshot) => {
             const labeledTaskImagePaths: Record<string, ImageLabeledData> = {};
             let index = 0;
             const promises: Promise<void>[] = [];
             snapshot.forEach((doc) => {
                const dataId = doc.id;
                const taskDataLabelsRef = getTaskDataLabelCollectionRef(taskId, dataId);
                if (taskDataLabelsRef) {
                    promises.push(getDocs(taskDataLabelsRef).then((labelsSnapshot) => {
                        labelsSnapshot.forEach((labelSnapshot) => {
                            const labelId = labelSnapshot.id;
                            const label = labelSnapshot.data();
                            if (label?.path && label?.caption) {
                                const id = `${dataId}/${labelId}`;
                                labeledTaskImagePaths[id] = {
                                    path: label.path,
                                    caption: label.caption,
                                    dataId: doc.id,
                                    labelId,
                                };
                            }
                            index += 1;
                        });
                    }));
                }
             });
             await Promise.all(promises);
             console.log(`Snapshot size: ${snapshot.size}; Index: ${index}`);
             return labeledTaskImagePaths;
         });
     }
     return Promise.resolve({});
}

export function getImageUrlFromImageLabeledData(data: ImageLabeledData) {
    if (firebaseStorage && data?.path) {
        return getDownloadURL(storageRef(firebaseStorage, data.path));
    }
    return Promise.resolve('');
}

export function getRenderJobRef(
    renderJobId: string,
    renderJobsCollection = RENDER_JOBS_COLLECTION_V1,
) {
    return firestore && doc(firestore, `${renderJobsCollection}/${renderJobId}`);
}

export function downloadFileBytesFromStorage(path: string) {
    return firebaseStorage && getBytes(storageRef(firebaseStorage, path));
}

export function deleteTaskData(
    taskId: string,
    dataId: string,
) {
    if (taskId && dataId) {
        const taskDataRef = getTaskDataRef(taskId, dataId);
        if (taskDataRef) {
            return deleteDoc(taskDataRef);
        }
    }
    return Promise.resolve();
}

export function deleteImageFromStorage(path: string) {
    if (!firebaseStorage || !path) {
        return Promise.resolve();
    }
    return deleteObject(storageRef(firebaseStorage, path));
}