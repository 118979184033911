import React from 'react';
import { FirebaseAuthProvider } from './useFirebaseAuth';

export function AppContext({
    children,
}: {
    children: React.ReactNode,
}) {
    return (
        <FirebaseAuthProvider>
            {children}
        </FirebaseAuthProvider>
    )
}