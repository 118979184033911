import { PercentCrop } from 'react-image-crop';

export type ImageData = {
    key?: string,
    url?: string,
}

export type ImageLabelData = {
    crop: PercentCrop,
    caption: string,
    path?: string,
    url?: string,
};

export type ImageLabeledData = Partial<ImageLabelData> & {
    path: string,
    dataId?: string,
    labelId?: string,
}

export function getDefaultImageLabelData(): ImageLabelData {
    return ({
        crop: {
            unit: '%',
            width: 80,
            height: 80,
            x: 10,
            y: 10,
        },
        caption: '',
    });
}

export type ImageLabelDataRecord = Partial<ImageData> & {
    record: Record<string, ImageLabelData>,
};

export type ImageLabelDataSet = Record<string, ImageLabelDataRecord>;

export type Task = {
    name: string,
    prefix: string,
    size: number,
}

export function isTask(task: any): task is Task {
    return task && task.name && task.prefix && typeof(task.size) === 'number';
}

export type TaskData = {
    name: string,
    path: string,
    labels: Record<string, ImageLabelData>,
}

export function isTaskData(task: any): task is TaskData {
    return task && task?.name && task?.path;
}

export type DisplayMessageType = 'info' | 'error';

export const RENDER_JOBS_COLLECTION_V1 = "RenderJob";

export type RenderVersion = "RenderJob";

export const noop = () => null;

export enum RenderStatus {
    Queued = "2",
    Rendering = "1",
    Stopped = "0",
    Error = "-1",
}