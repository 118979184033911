import React, { useEffect, useState } from 'react';
import {
    browserLocalPersistence,
    getAuth,
    setPersistence,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useFirebaseAuthUser } from './useFirebaseAuth';
export function LoginFullscreen() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    
    const user = useFirebaseAuthUser();

    useEffect(() => {
        if (user?.uid) {
            setMessage('Logged in, redirecting ...');
            navigate('/');
        }
    }, [user, navigate]);

    return (
        <div className='w-screen h-screen flex justify-center items-center bg-slate-300 text-lg'>
            <div className='w-[90%] md:w-[500px] px-6 py-8 rounded-md flex flex-col bg-slate-100 items-center shadow-md'>
                <div className='mb-4'>
                    Welcome
                </div>
                <div className='w-full flex flex-row items-center my-4'>
                    <div 
                        className='w-[100px]'
                    >
                        Email
                    </div>
                    <input 
                        type="Email" 
                        className='grow bg-slate-200 rounded px-3 py-2'
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget?.value)}
                    />
                </div>
                <div className='w-full flex flex-row items-center my-4'>
                    <div 
                        className='w-[100px]'
                    >
                        Password
                    </div>
                    <input 
                        type="Password" 
                        className='grow bg-slate-200 rounded px-3 py-2'
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget?.value)}
                    />
                </div>
                <div className='my-4'>{message}</div>
                <button
                    className='w-fit mt-4 select-none cursor-pointer px-4 py-3 bg-blue-500 hover:bg-blue-600 rounded-md text-white'
                    onClick={() => {

                        const auth = getAuth();
                        setPersistence(auth, browserLocalPersistence)
                        .then(() => {
                            return signInWithEmailAndPassword(auth, email, password);
                        })
                        .then(() => {
                            setMessage('Logged in, redirecting ...');
                            navigate('/');
                        })
                        .catch((error) => {
                            console.error(error?.message || error);
                            setMessage(error?.message);
                        });
                    
                    }}
                >
                    Login
                </button>
            </div>
        </div>
    )
}