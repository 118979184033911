import { useEffect, useState } from 'react';
import * as Slider from '@radix-ui/react-slider';

export function MenuNumberSlider({
    name,
    value,
    setValue,
    defaultValue = 50,
    min = 0,
    max = 100,
    step = 1,
}: {
    name: string,
    value: number,
    setValue: (value: number) => void,
    defaultValue?: number,
    min?: number,
    max?: number,
    step?: number,
}) {
    const [valueInput, setValueInput] = useState(String(value));

    useEffect(() => setValueInput(String(value)), [value]);

    return (
        <div className='w-full flex flex-col flex-center items-center mb-4'>
            <div className='w-full flex flex-row items-center'>
                <div className='grow text-left'>
                    {name}
                </div>
                <input
                    type="number"
                    className='w-[120px] px-4 py-3 bg-zinc-800 rounded-md focus-visible:outline-none border border-zinc-700 focus:border-lime-500 transition-colors'
                    value={valueInput}
                    onChange={e => setValueInput(e.currentTarget.value)}
                    onBlur={() => {
                        const value = parseInt(valueInput);
                        if (value && !isNaN(value)) {
                            setValue(Math.max(Math.min(value, max), min));
                        } else {
                            setValueInput(String(value));
                        }
                    }}
                />
            </div>
            <Slider.Root
                defaultValue={[defaultValue]}
                min={min}
                max={max}
                step={step}
                value={[value]}
                onValueChange={(v) => setValue(v[0])}
                className="relative my-6 w-full flex items-center"
            >
                <Slider.Track
                    className='w-full relative rounded-full h-[5px] bg-zinc-200'
                >
                    <Slider.Range
                        className='absolute bg-lime-500 rounded-full h-full'
                    />
                </Slider.Track>
                <Slider.Thumb
                    className='block w-[20px] h-[20px] rounded-full bg-white border border-lime-200 focus:outline-none focus:border-lime-300 shadow shadow-lime-500/20 focus:shadow-lime-600/30 transition-colors'
                />
            </Slider.Root>
        </div>
    )
}