import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getTaskNames } from './firebaseUtils';
import { RequireAuth } from './RequireAuth';

function DashboardTask({
    taskId,
    taskName,
}: {
    taskId: string,
    taskName: string,
}) {
    return (
        <Link
            to={`/task/${taskId}`}
            className='flex flex-col text-left border boder-slate-100 hover:shadow-lg rounded-md px-4 py-8 select-none cursor-pointer transition-shadows'
        >
            {taskName}
        </Link>
    )
}

export function Dashboard() {
    const [taskNames, setTaskNames] = useState<Record<string, string>>({});
    useEffect(() => {
        getTaskNames().then(setTaskNames);
    }, []);
    return (
        <RequireAuth>
            <div className='w-full px-8 py-20 flex flex-col text-slate-800'>
                <div className='text-2xl font-bold my-8'>
                    Tasks
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                    {Object.entries(taskNames || {})?.map(([id, name]) => <DashboardTask key={id} taskId={id} taskName={name}/>)}
                </div>
            </div>
        </RequireAuth>
    )
}