import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { getImageUrlFromPath, getLabeledTaskImagePaths } from './firebaseUtils';
import { RequireAuth } from './RequireAuth';
import { ImageLabeledData } from './types';

function LabeledDataCard({
    taskId,
    dataId = '',
    caption,
    path,
    labelId = '',
    getImageUrl,
}: ImageLabeledData & {
    taskId: string,
    getImageUrl: () => Promise<string>
}) {
    const [imageUrl, setImageUrl] = React.useState('');
    React.useEffect(() => {
        getImageUrl().then(setImageUrl)
    }, [getImageUrl]);
    const searchParams = {
        dataId,
        labelId,
    };
    return (
        <Link
            to={`/task/${taskId}/?${new URLSearchParams(searchParams).toString()}`}
            className='flex flex-col text-left border boder-slate-100 shadow hover:shadow-lg rounded-md p-4 text-lg transition-shadow'
        >
            {imageUrl ? <img alt={caption} className="rounded-md border border-slate-200 bg-slate-100" src={imageUrl}/> : <div className='rounded-md border border-slate-200 bg-slate-100 h-0 w-[100%] pb-[100%]'/>}
            <div className='my-4'>
                {caption}
            </div>
        </Link>
    )
}

export function LabeledDataGallary() {
    const {taskId} = useParams();

    const [imagePaths, setImagePaths] = React.useState<Record<string, ImageLabeledData>>({});
    const [imageIndex, setImageIndex] = React.useState(12);

    const imageUrls = React.useRef<Record<string, string>>({});

    React.useEffect(() => {
        if (taskId) {
            getLabeledTaskImagePaths(taskId).then(setImagePaths);
        }
    }, [taskId]);

    return (
        <div className='w-full px-8 py-20 flex flex-col text-slate-800'>
            <div className='text-2xl font-bold my-8'>
                Labeled Data
            </div>
            <div className='w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                {taskId && Object.entries(imagePaths).slice(0, Math.min(imageIndex, Object.keys(imagePaths).length)).map(([imageId, imageData]) => {
                    return imageData && (
                        <LabeledDataCard
                            key={imageId}
                            {...imageData}
                            taskId={taskId}
                            getImageUrl={() => {
                                if (imageUrls.current[imageId]) {
                                    return Promise.resolve(imageUrls.current[imageId]);
                                }
                                return getImageUrlFromPath(imageData.path).then((url) => {
                                    if (url) {
                                        imageUrls.current[imageId] = url;
                                    }
                                    return url;
                                });
                            }}
                        />
                    );
                })}
            </div>
            <button 
                className='w-full rounded-md bg-blue-500 hover:bg-blue-600 text-xl text-white p-4 my-8 select-none cursor-pointer transition-colors'
                onClick={() => setImageIndex(prev => Math.min(prev + 12, Object.keys(imagePaths).length))}
            >
                Load more data
            </button>
        </div>
    )
}