import React from 'react';
import {initAwsConfig} from './AwsConfig';
import { Editor } from './Editor';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Dashboard } from './Dashboard';
import { getFirebaseApp } from './firebaseUtils';
import { AppContext } from './AppContext';
import { LoginFullscreen } from './Login';
import { LabeledDataGallary } from './Labeled';
import { BasicPainter } from './Painter/BasicPainter';

getFirebaseApp();

initAwsConfig();

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard/>}/>
        <Route path="/login" element={<LoginFullscreen/>}/>
        <Route path="/task" element={<Navigate replace to="/"/>}/>
        <Route path="/task/:taskId" element={<Editor/>}/>
        <Route path="/labeled/:taskId" element={<LabeledDataGallary/>}/>
        <Route path="/editor" element={<BasicPainter/>}/>
      </Routes>
    </>
  )
}


function App() {
  return (
    <AppContext>
      <div className="App">
        <BrowserRouter>
          <AppRoutes/>
        </BrowserRouter>
      </div>
    </AppContext>
  );
}

export default App;
