import { useCallback, useEffect, useRef, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { useFirebaseAuthUser } from '../useFirebaseAuth';
import { CrossIcon } from '../Icons/CrossIcon';
import { getFirebaseApp, getImageUrlFromPath, getRenderJobRef } from '../firebaseUtils';
import { httpsCallable } from 'firebase/functions';
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import { nanoid } from 'nanoid';
import { DisplayMessageType, noop, RenderStatus, RenderVersion, RENDER_JOBS_COLLECTION_V1 } from '../types';
import { onSnapshot } from 'firebase/firestore';
import { AppContainer } from '../AppContainer';
import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon, CheckIcon } from '@radix-ui/react-icons';
import { MenuNumberSlider } from './MenuSlider';

const renderButtonColor = "#84cc16";

const renderButtonProgressColor = "#a3e635";

const validImageFileType = new Set(['image/png', 'image/jpeg']);

function parseStrength(strength: number) {
    return Math.max(Math.min(strength * 0.01, 1), 0);
}

function handleUploadImage(
    image?: Uint8Array | Blob | ArrayBuffer,
) {
    if (image) {
        const {firebaseAuth, firebaseStorage} = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        const imageId = nanoid();
        const inputImagePath = `users/${uid}/${imageId}.png`;
        const imageRef = storageRef(
            firebaseStorage,
            inputImagePath,
        );
        return uploadBytes(
            imageRef,
            image,
            {
                contentType: 'image/png',
            },
        ).then(() => inputImagePath)
    }
    return Promise.resolve(undefined);
}

enum Styles {
    None="None",
    Someform="Abstract Blobs",
    EcommerceStillLife="Still Life",
    Icon3d="3D Icon",
    Illustration3d="3D Illustration",
}

enum EngineType {
    Ando='Ando',
    Mosaic='Mosaic',
}

function addStyleToPrompt(prompt: string, style?: Styles) {
    prompt.trim();
    if (style === Styles.Someform) {
        return `${prompt}, zCcxEe50-Yk9Mvi style`;
    } else if (style === Styles.EcommerceStillLife) {
        return `${prompt}, vSL45O0dkyP5Aov style`;
    } else if (style === Styles.Icon3d) {
        return `${prompt}, zkz 3d icon`;
    } else if (style === Styles.Illustration3d) {
        return `${prompt}, Bg7FhAR8Ky style`;
    }
    return prompt;
}

function getEngineType(style?: Styles) {
    if (style === Styles.None || style === Styles.Icon3d || style === Styles.Illustration3d) {
        return EngineType.Ando;
    }
    return EngineType.Mosaic;
}

function callModelBackendApi({
    prompt,
    image,
    width,
    height,
    steps,
    strength,
    engine = EngineType.Ando,
    renderVersion = RENDER_JOBS_COLLECTION_V1,
    onRenderStart = noop,
    onRenderError = noop,
    displayMessage = noop,
}: {
    prompt: string,
    image?: Uint8Array | Blob | ArrayBuffer,
    width?: number,
    height?: number,
    steps?: number,
    engine?: EngineType,
    strength: number,
    renderVersion?: RenderVersion,
    onRenderStart: (jobId?: string) => void,
    onRenderError: (error?: any) => void,
    displayMessage: (type: DisplayMessageType, message: string) => void,
}) {
    const {firebaseFunctions} = getFirebaseApp();

    const startRenderJob = httpsCallable(
        firebaseFunctions,
        'startRenderJob',
    );

    console.log('Start uploading image');

    return handleUploadImage(
        image
    ).then((inputImagePath) => {
        console.log(`Start render job with engine ${engine}`);
        return startRenderJob({
            prompt,
            strength: parseStrength(strength),
            inputPath: inputImagePath,
            samples: 1,
            steps,
            engine,
            version: renderVersion,
            width,
            height,
            jobType: 'render',
        });
    }).then((res) => {
        if (res) {
            const jobId = (res.data as any)?.jobId;
            if (jobId) {
                console.log(`Render started with job id ${jobId}`);
                onRenderStart(jobId);
            } else {
                displayMessage('error', (res.data as any).message || 'Unknown render error. Please try again.');
                onRenderError?.();
            }
        } else {
            console.log('No valid resposne');
        }
    }).catch((error) => {
        console.warn(error);
        displayMessage('error', 'Render process error.');
        onRenderError?.(error);
    });

}

export function BasicPainter() {

    const user = useFirebaseAuthUser();

    const [prompt, setPrompt] = useState<string>('');

    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const [outputImageUrl, setOutputImageUrl] = useState<string | null>(null);

    const [imageSize, setImageSize] = useState<[number, number]>([0, 0]);

    const [renderJobId, setRenderJobId] = useState<string | null>('');

    const [isRendering, setIsRendering] = useState(false);

    const [renderProgress, setRenderProgress] = useState(0);

    const [style, setStyle] = useState<Styles>(Styles.None);

    const [strength, setStrength] = useState(80);

    const [steps, setSteps] = useState(100);
    const [stepsInput, setStepsInput] = useState(String(steps));
    
    useEffect(() => setStepsInput(String(steps)), [steps]);

    const imageRef = useRef<Blob>();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const numFiles = acceptedFiles?.length || 0;
        for (let i = 0; i < numFiles; ++i) {
            const file = acceptedFiles[i];
            if (file && validImageFileType.has(file.type)) {
                setImageUrl(URL.createObjectURL(file));
                imageRef.current = file;
                return;
            }
        }
    }, []);


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'image/png': ['.png', '.PNG'],
            'image/jpeg': ['.jpg', '.jpeg', '.JPG', '.JPEG'],
        }
    });

    const onRender = () => {

        setIsRendering(true);

        const promptEdited = addStyleToPrompt(prompt, style);

        console.log(promptEdited);

        callModelBackendApi({
            prompt: promptEdited,
            strength,
            image: imageRef.current,
            steps,
            engine: getEngineType(style),
            width: imageSize[0],
            height: imageSize[1],
            onRenderStart: (jobId) => {
                if (!jobId) {
                    console.warn('Render job id is invalid');
                    return;
                }

                setIsRendering(true);
                setRenderJobId(jobId);
                
                const renderJobRef = getRenderJobRef(jobId, RENDER_JOBS_COLLECTION_V1);
                
                if (renderJobRef) {

                    onSnapshot(renderJobRef, (snapshot) => {
                        if (snapshot) {
                            const job = snapshot?.data?.();
                            if (job) {
                                const status = job.status;
                                if (status === RenderStatus.Stopped) {
                                  // setClientStatus(ClientStatus.Idle);
                                  setIsRendering(false);
                                  setRenderProgress(1);
                
                                  const outputPaths = job.outputPaths;
                                  if (Array.isArray(outputPaths) && outputPaths.length > 0) {
                                    // Download image from the output path
                                    Promise.all(outputPaths.map((imagePath, index) => {
                                      if (imagePath) {
                                        console.log(`Download image ${imagePath}`);
                                        getImageUrlFromPath(imagePath).then((url) => {
                                            console.log(url);
                                            setOutputImageUrl(url);
                                        });
                                      }
                                      return Promise.resolve();
                                    }));
                                  } else {
                                    // displayMessage('error', 'No valid render output, please try again.');
                                    console.error('No valid render output, please try again.');
                                  }
                                } else if (status === RenderStatus.Error) {
                
                                  // Display error message
                
                                  const errorMessage = job.errorMessage || 'Unknown server error. Please try again.';
                
                                  // displayMessage('error', errorMessage);
                                  
                                  // setClientStatus(ClientStatus.Idle);
                                  setIsRendering(false);
                                  setRenderJobId(null);

                                  console.error(errorMessage);
                
                                } else {
                                  const progress = job.progress;
                                  setRenderProgress(progress);
                                }
                            }
                        }
                    });
                }

            },
            onRenderError: (error?) => {
                console.error(error);
            },
            displayMessage: (type, message) => {
                console.log(message);
            },
        });
    }

    return (
        <AppContainer>
            <div className='w-full flex flex-col text-base mt-12'>
                <div
                    className='text-center text-xl pb-8 mb-8 border-b border-zinc-800 text-zinc-500 select-none'
                >
                    Internal Development Editor
                    <span
                        className='mx-4 px-2 py-1 text-sm text-zinc-600 bg-zinc-800 border border-zinc-700/30 rounded-md'
                    >v0.0.1</span>
                </div>
                <div className='flex flex-col md:flex-row w-full'>
                    <div className='md:w-1/2 md:mr-4 flex flex-col items-center flex-center'>
                        <div className='w-full flex flex-col flex-center items-center mb-4'>
                            <div className='w-full text-left'>
                                Prompt
                            </div>
                            <textarea
                                className='w-full px-4 py-3 mx-8 my-6 text-zinc-200 bg-zinc-500/10 rounded-md focus:outline-none border border-zinc-500/20 hover:border-zinc-500/50 focus:border-zinc-500'
                                placeholder='a house'
                                value={prompt}
                                rows={3}
                                onChange={(e) => {
                                    const value = e?.currentTarget?.value;
                                    setPrompt(value || '');
                                }}
                            />
                        </div>
                        <div className='w-full flex flex-row flex-center items-center mb-4'>
                            <div className='flex-1 text-left'>
                                Style
                            </div>
                            <Select.Root
                                value={style}
                                onValueChange={(value) => {
                                    if (value) {
                                        setStyle(value as Styles);
                                    }
                                }}
                            >
                                <Select.Trigger className='flex flex-row justify-center items-center px-4 py-3 rounded-md hover:text-lime-500 border border-zinc-500/20 focus-visible:outline-none focus-visible:border-lime-500 transition-colors' aria-label='Style'>
                                    <Select.Value placeholder={Styles.None}/>
                                    <Select.Icon className='ml-2'>
                                        <ChevronDownIcon/>
                                    </Select.Icon>
                                </Select.Trigger>
                                <Select.Content className='px-4 py-3 rounded-md bg-zinc-800 border border-zinc-500/20 text-slate-300 overflow-hidden z-50 shadow-md'>
                                    <Select.Viewport className=''>
                                        {Object.entries(Styles).map(([key, val]) => {
                                            return (
                                                <Select.Item
                                                    key={key}
                                                    value={val}
                                                    className='flex flex-row justify-left items-center px-4 py-3 hover:outline-none select-none cursor-pointer hover:text-lime-500 z-50'
                                                >
                                                    <Select.ItemText>{val}</Select.ItemText>
                                                    <Select.ItemIndicator className='ml-2'>
                                                        <CheckIcon />
                                                    </Select.ItemIndicator>
                                                </Select.Item>
                                            )
                                        })}
                                    </Select.Viewport>
                                </Select.Content>
                            </Select.Root>
                        </div>
                        <MenuNumberSlider
                            name={'Steps'}
                            value={steps}
                            setValue={setSteps}
                            min={0}
                            max={200}
                            step={1}
                        />
                        <MenuNumberSlider
                            name={'Prompt Weight'}
                            value={strength}
                            setValue={setStrength}
                            min={0}
                            max={100}
                            step={1}
                        />
                        <div className='w-full flex flex-col flex-center items-center mb-4'>
                            <div className='w-full text-left'>
                                Image Reference
                            </div>
                            <div className='w-full flex flex-col md:flex-row md:h-80 mx-8 my-6'>
                                {
                                    imageUrl && 
                                    <div className='relative w-full md:w-1/2 h-80 md:h-full px-4 py-3 mr-4 mb-4 rounded-md bg-zinc-500/10 flex flex-col flex-center items-center focus:outline-none border border-zinc-500/20'>
                                        <CrossIcon 
                                            width={18} 
                                            className='absolute right-0 mr-3 text-zinc-500 hover:text-zinc-600 cursor-pointer'
                                            onClick={() => {
                                                setImageUrl(null);
                                            }}
                                        />
                                        <img className='object-cover my-4 h-full min-h-0 min-w-0 rounded-md' alt="preview" src={imageUrl}/>
                                    </div>
                                }
                                <div className='w-full md:w-1/2 h-80 md:h-full px-4 py-3 rounded-md bg-zinc-500/10 cursor-pointer flex flex-center items-center focus:outline-none border border-zinc-500/20 hover:border-zinc-500/50 focus:border-zinc-500' {...getRootProps()}>
                                    <input {...getInputProps()}/>
                                    <p className='w-full text-center text-zinc-500'>
                                        {isDragActive ?
                                        "Drop the image here ..." :
                                        `Click here to ${imageUrl ? "replace" : "upload"} image`}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex flex-row flex-center items-center mb-12 md:mb-0 text-zinc-900 font-semibold'>
                            {
                                isRendering ?
                                <div
                                    className='grow bg-lime-500 rounded-md px-4 py-3 select-none cursor-disabled transition-colors'
                                    style={{
                                        background: `linear-gradient(90deg, ${renderButtonColor} ${renderProgress * 100}%, ${renderButtonProgressColor} ${renderProgress * 100 + 0.01}%)`
                                      }}
                                >
                                    Rendering ...
                                </div> :
                                <div 
                                    className='grow bg-lime-500 hover:bg-lime-600 rounded-md px-4 py-3 select-none cursor-pointer transition-colors'
                                    onClick={() => {
                                        if (user?.uid) {
                                            // Load the specific render Api
                                            onRender();
                                        } else {
                                            console.warn('User not logged in.');
                                        }
                                    }}
                                >
                                    {user?.uid ? "Render" : "Login to render"}
                                </div>
                            }
                        </div>
                    </div>
                    <div className='grow flex flex-col md:ml-4 flex flex-col'>
                        <div className='w-full text-left mb-6'>
                            Output
                        </div>
                        <div 
                            className='relative flex h-full min-h-[256px] justify-center items-center rounded-md bg-zinc-500/10'
                        >
                            <img
                                alt="output"
                                className='w-full bg-contain bg-no-repeat bg-center'
                                style={{
                                    display: outputImageUrl ? 'block' : 'none',
                                }}
                                src={outputImageUrl || ''}
                                onLoad={e => {
                                    const currentTarget = e.currentTarget;
                                    if (currentTarget) {
                                        currentTarget.style.display = 'block';
                                    }
                                }}
                                onError={(e) => {
                                    const currentTarget = e.currentTarget;
                                    if (currentTarget) {
                                        currentTarget.style.display = 'none';
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AppContainer>
    )
}