import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getFirebaseApp } from './firebaseUtils';
import { useFirebaseAuthUser } from './useFirebaseAuth';

export function RequireAuth({
    children,
}: {
    children: React.ReactNode,
}) {
    
    const user = useFirebaseAuthUser();

    return (
        user?.uid ? <>{children}</> : <Navigate replace to='/login'/> 
    );
}