import React from 'react';

export function AppContainer({
    children,
} :{
    children: React.ReactNode
}) {
    return (
        <div className='flex flex-col justify-start items-center w-full overflow-hidden bg-zinc-900 text-zinc-300'>
             <div className='App w-full 2xl:w-[1366px] min-h-screen flex flex-col items-center px-6 2xl:px-0 pb-12 overflow-hidden'>
                {children}
             </div>
        </div>
    )
}