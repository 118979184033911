import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, {PixelCrop, PercentCrop, centerCrop, convertToPixelCrop, convertToPercentCrop, makeAspectCrop, } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getDefaultImageLabelData, ImageLabelData, ImageLabelDataSet, TaskData } from './types';
import { nanoid } from 'nanoid';

import { useHotkeys } from 'react-hotkeys-hook'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { deleteImageFromStorage, deleteTaskData, getImageUrlFromTaskData, getTaskData, getTaskDataIds, updateTaskDataLabels } from './firebaseUtils';
import { LoadingScreen } from './Loading';
import { RequireAuth } from './RequireAuth';

const defaultImageLabelKey = 'default';

function getImageLabel({
    imageLabelDataset,
    setImageLabelDataset,
    imageId,
    imageLabelKey,
}: {
    imageLabelDataset: ImageLabelDataSet,
    setImageLabelDataset: (value: ImageLabelDataSet) => void,
    imageId: string,
    imageLabelKey: string,
}) {
    const imageLabels = imageLabelDataset[imageId];
    if (!imageLabels) {
        const output = getDefaultImageLabelData();
        setImageLabelDataset({
            ...imageLabelDataset,
            [imageId]: {
                record: {
                    [defaultImageLabelKey]: output,
                },
            },
        });
        return output;
    }
    if (imageLabels.record?.[imageLabelKey] == null) {
        const output = getDefaultImageLabelData();
        setImageLabelDataset({
            ...imageLabelDataset,
            [imageId]: {
                ...imageLabels,
                record: {
                    ...(imageLabels.record || {}),
                    [imageLabelKey]: output,
                },
            }
        });
        return output;
    }
    return imageLabels.record[imageLabelKey];
}

function setImageCrop({
    crop,
    imageId,
    imageLabelKey,
    setImageLabelDataset,
}: {
    crop: PercentCrop,
    imageId: string,
    imageLabelKey: string,
    setImageLabelDataset: (callback: (prev: ImageLabelDataSet) => ImageLabelDataSet) => void,
}) {
    setImageLabelDataset((dataset) => {
        return {
            ...(dataset || {}),
            [imageId]: {
                ...(dataset?.[imageId] || {}),
                record: {
                    ...(dataset?.[imageId]?.record || {}),
                    [imageLabelKey]: {
                        caption: dataset?.[imageId]?.record?.[imageLabelKey]?.caption || '',
                        crop,
                    },
                }
            }
        }
    });
}

function setImageCaption({
    caption,
    imageId,
    imageLabelKey,
    setImageLabelDataset,
}: {
    caption: string,
    imageId: string,
    imageLabelKey: string,
    setImageLabelDataset: (callback: (prev: ImageLabelDataSet) => ImageLabelDataSet) => void,
}) {
    setImageLabelDataset((dataset) => {
        return {
            ...(dataset || {}),
            [imageId]: {
                ...(dataset?.[imageId] || {}),
                record: {
                    ...(dataset?.[imageId]?.record || {}),
                    [imageLabelKey]: {
                        crop: dataset?.[imageId]?.record?.[imageLabelKey]?.crop,
                        caption,
                    },
                }
            }
        };
    });
}

function addImageLabelData({
    imageId,
    imageLabelData,
    setImageLabelDataset,
}: {
    imageId: string,
    imageLabelData?: Partial<ImageLabelData>,
    setImageLabelDataset: (callback: (prev: ImageLabelDataSet) => ImageLabelDataSet) => void,
}) {
    const key = nanoid();
    setImageLabelDataset((dataset) => {
        return {
            ...(dataset || {}),
            [imageId]: {
                ...(dataset?.[imageId] || {}),
                record: {
                    ...(dataset?.[imageId]?.record || {}),
                    [key]: {
                        ...getDefaultImageLabelData(),
                        ...(imageLabelData || {}),
                    },
                }
            }
        };
    });
    return key;
}

function removeImageLabelData({
    imageId,
    imageLabelKey,
    setImageLabelDataset,
}: {
    imageId: string,
    imageLabelKey: string,
    setImageLabelDataset: (callback: (prev: ImageLabelDataSet) => ImageLabelDataSet) => void,
}) {
    console.log(`Remove image ${imageId} key ${imageLabelKey}`);
    setImageLabelDataset((dataset) => {
        if (dataset?.[imageId]) {
            delete dataset[imageId].record?.[imageLabelKey];
        }
        return {...dataset};
    });
}

function setImageLabelDataRecord({
    imageId,
    record,
    setImageLabelDataset,
}: {
    imageId: string,
    record: Record<string, ImageLabelData>,
    setImageLabelDataset: (callback: (prev: ImageLabelDataSet) => ImageLabelDataSet) => void,
}) {
    setImageLabelDataset((dataset) => {
        return {
            ...(dataset || {}),
            [imageId]: {
                ...(dataset[imageId] || {}),
                record,
            },
        };
    });
}

function getCenterCrop(
    percentCrop: PercentCrop,
    width: number,
    height: number,
) {
    return centerCrop(
        convertToPixelCrop(percentCrop, width, height),
        width,
        height
    );
}

const CaptionTextBox = React.forwardRef(function ({
    imageId,
    imageLabel,
    imageLabelKey,
    setImageLabelKey,
    setImageLabelDataset,
    ...props
}: React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    imageId: string,
    imageLabelKey: string,
    imageLabel: ImageLabelData,
    setImageLabelKey: (key: string) => void,
    setImageLabelDataset: (callback: (prev: ImageLabelDataSet) => ImageLabelDataSet) => void,
}, ref: React.LegacyRef<HTMLTextAreaElement> | undefined) {
    const [text, setText] = useState(imageLabel.caption);
    useEffect(() => {
        setText(imageLabel?.caption);
    }, [imageLabel?.caption]);
    return (
        <textarea
            ref={ref}
            className='p-2 w-full mb-4  focus-visible:outline-none border rounded-md focus:border-slate-300'
            placeholder='Prompt'
            value={text}
            onChange={e => setText(e.currentTarget?.value)}
            onFocus={() => {
                setImageLabelKey(imageLabelKey);
            }}
            onBlur={(e) => {
                const text = e.currentTarget?.value;
                setImageCaption({
                    caption: text,
                    imageId,
                    imageLabelKey,
                    setImageLabelDataset,
                });
            }}
            {...props}
        />
    )
})

const flexRowCenter = 'flex flex-row justify-center items-center';

const defaultImageSrc = '';

const buttonClassName = 'bg-blue-500 hover:bg-blue-600 text-white text-base px-4 py-2 my-6 rounded-md cursor-pointer select-none transition-colors';

const hotkeyBlueClassName = 'w-fit ml-4 p-2 rounded-md bg-blue-400 border-1 border-blue-300/30 shadow-lg transition-colors text-sm'
const hotkeyRedClassName = 'w-fit ml-4 p-2 rounded-md bg-red-400 border border-red-300/10 shadow-lg transition-colors text-sm'

export function Editor() {
    const {taskId} = useParams();

    const [searchParams, ] = useSearchParams();
    const updatedSearchParams = useRef(false);

    const [taskDataIds, setTaskDataIds] = useState<string[]>([]);

    React.useEffect(() => {
        if (taskId) {
            getTaskDataIds(taskId).then(setTaskDataIds);
        }
    }, [taskId]);

    const [imageIndex, setImageIndexInternal] = useState(0);
    const [imageInputIndex, setImageInputIndex] = useState<string>(String(imageIndex));

    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
        setIsLoading(true);
        setImageInputIndex(String(imageIndex));
    }, [imageIndex]);

    const [imageSrc, setImageSrc] = useState(defaultImageSrc);

    const [taskData, setTaskData] = useState<TaskData | undefined>(undefined);

    const taskDataId = React.useMemo(() => taskDataIds[imageIndex] || '', [imageIndex,taskDataIds]);

    const [cropAspect, setCropAspect] = useState<number | undefined>(undefined);

    const [imageLabelKey, setImageLabelKey] = useState<string>(defaultImageLabelKey);
    const [imageLabelDataset, setImageLabelDataset] = useState<ImageLabelDataSet>({});
    
    const activeImageLabelElementRef = useRef<HTMLDivElement | null>(null);
    const activeCaptionTextboxRef = useRef<HTMLTextAreaElement | null>(null);
    const isShiftDownRef = useRef(false);

    const imageElementRef = useRef<HTMLImageElement>();
    const removedImagesSetRef = useRef<Set<string>>(new Set([]));

    React.useEffect(() => {
        if (taskId) {
            if (taskDataId) {
                getTaskData(taskId, taskDataId).then(taskData => {
                    if (taskData) {
                        setTaskData(taskData);
                        setImageLabelDataRecord({
                            imageId: taskDataId,
                            record: taskData.labels,
                            setImageLabelDataset,
                        });
                        return getImageUrlFromTaskData(taskData).then(setImageSrc).then(() => setIsLoading(false));
                    }
                });
            }
        }
    }, [taskDataIds, taskId, taskDataId]);
    
    const crop = React.useMemo(() => {
        if (!removedImagesSetRef.current.has(imageLabelKey)) {
            return getImageLabel({
                imageId: taskDataId,
                imageLabelKey,
                imageLabelDataset,
                setImageLabelDataset,
            })?.crop;
        }
        return undefined;
    }, [taskDataId, imageLabelKey, imageLabelDataset]);

    const handleDeleteTaskData = React.useCallback((taskId: string, dataId: string) => {
        console.log(`Delete task ${taskId} data ${dataId}`);
        setTaskDataIds(dataIds => dataIds.filter(id => id !== dataId));
        deleteTaskData(taskId, dataId);
        if (taskData) {
            console.log(`Delete storage image from path ${taskData.path}`);
            deleteImageFromStorage(taskData.path);
        }
    }, [taskData]);

    const setImageIndex = React.useCallback((arg: React.SetStateAction<number>) => {
        if (!arg) {
            return;
        }
        if (typeof(arg) === 'number') {
            if (isNaN(arg)) {
                return;
            }
            arg = Math.min(Math.max(arg, 0), Math.max(taskDataIds.length - 1, 0));
        }
        setImageIndexInternal(arg);
        if (taskId) {
            // Upload the labels
            updateTaskDataLabels(
                taskId,
                taskDataId,
                imageLabelDataset?.[taskDataId]?.record,
            );
        }
    }, [taskId, taskDataId, imageLabelDataset, taskDataIds.length]);
    

    React.useEffect(() => {
        if (searchParams && imageLabelDataset && !updatedSearchParams.current) {
            const dataId = searchParams.get('dataId');
            const labelId = searchParams.get('labelId');
            if (dataId && labelId) {
                console.log(`Data id ${dataId}; Label id ${labelId}`);
                const newImageIndex = taskDataIds.findIndex(id => id === dataId);
                if (newImageIndex >= 0) {
                    setImageIndex(newImageIndex);
                    setImageLabelKey(labelId);
                    updatedSearchParams.current = true;
                }
            }
        }
    }, [taskDataIds, searchParams, imageLabelDataset, setImageIndex, taskDataId]);

    const incrementImageIndex = useCallback((delta: number) => {
        setImageIndex(index => Math.min(Math.max(index + delta, 0), Math.max(taskDataIds.length - 1, 0)));
        setImageLabelKey(defaultImageLabelKey);
    }, [taskDataIds.length, setImageIndex]);

    const incrementImageLabelKey = useCallback((delta: number) => {
        setImageLabelKey((key) => {
            const imageLabelKeys = Object.keys(imageLabelDataset?.[taskDataId]?.record || {});
            if (imageLabelKeys.length > 0) {
                const index = imageLabelKeys.findIndex(v => v === key);
                return imageLabelKeys[Math.min(Math.max(index + delta, 0), Math.max(imageLabelKeys.length - 1, 0))];
            }
            return key;
        });
    }, [taskDataId, imageLabelDataset]);

    const addNewImageLabelData = useCallback(() => {
        const key = addImageLabelData({
            imageId: taskDataId,
            imageLabelData: imageLabelDataset[taskDataId]?.record?.[imageLabelKey],
            setImageLabelDataset,
        });
        setImageLabelKey(key);
    }, [taskDataId, imageLabelKey, imageLabelDataset]);

    const deleteImageLabelData = useCallback((key: string) => {
        if (key !== defaultImageLabelKey) {
            removedImagesSetRef.current.add(key);
        }
        setImageLabelKey(defaultImageLabelKey);
        removeImageLabelData({
            imageId: taskDataId,
            imageLabelKey: key,
            setImageLabelDataset,
        });
    }, [taskDataId]);

    const onImageLoad = useCallback<React.ReactEventHandler<HTMLImageElement>>((e) => {
        imageElementRef.current = e.currentTarget;
    }, []);

    const handleCenterCrop = useCallback((key: string) => {
        const width = imageElementRef.current?.naturalWidth || 0;
        const height = imageElementRef.current?.naturalHeight || 0;
        if (width > 0 && height > 0) {
            const percentCrop = imageLabelDataset[taskDataId]?.record?.[key]?.crop;
            if (percentCrop) {
                const crop = getCenterCrop(percentCrop, width, height);
                setImageCrop({crop: convertToPercentCrop(crop, width, height), imageId: taskDataId, imageLabelKey: key, setImageLabelDataset});
            }
        }
    }, [imageLabelDataset, taskDataId]);

    const handleSquareCrop = useCallback((key: string) => {
        const width = imageElementRef.current?.naturalWidth || 0;
        const height = imageElementRef.current?.naturalHeight || 0;
        if (width > 0 && height > 0) {
            const percentCrop = imageLabelDataset[taskDataId]?.record?.[key]?.crop;
            if (percentCrop) {
                const crop = makeAspectCrop(percentCrop, 1, width, height);
                setImageCrop({crop, imageId: taskDataId, imageLabelKey: key, setImageLabelDataset});
            }
        }
    }, [imageLabelDataset, taskDataId]);

    const handleFitCrop = useCallback((key: string) => {
        const width = imageElementRef.current?.naturalWidth || 0;
        const height = imageElementRef.current?.naturalHeight || 0;
        if (width > 0 && height > 0) {
            const percentCrop: PercentCrop = {
                unit: '%',
                width: 100,
                height: 100,
                x: 0,
                y: 0,
            };
            const crop = getCenterCrop(
                makeAspectCrop(percentCrop, 1, width, height),
                width,
                height,
            );
            setImageCrop({crop: convertToPercentCrop(crop, width, height), imageId: taskDataId, imageLabelKey: key, setImageLabelDataset});
        }
    }, [taskDataId]);

    const handleFillCrop = useCallback((key: string) => {
        setImageCrop({
            crop: {
                unit: '%',
                width: 100,
                height: 100,
                x: 0,
                y: 0,
            },
            imageId: taskDataId,
            imageLabelKey: key,
            setImageLabelDataset,
        });
    }, [taskDataId, setImageLabelDataset]);

    useHotkeys('left', (e) => {
        e.preventDefault();
        incrementImageIndex(-1);
    }, [incrementImageIndex]);
    useHotkeys('right', (e) => {
        e.preventDefault();
        incrementImageIndex(1);
    }, [incrementImageIndex]);
    useHotkeys('up', (e) => {
        e.preventDefault();
        incrementImageLabelKey(-1);
    }, [incrementImageLabelKey]);
    useHotkeys('down', (e) => {
        e.preventDefault();
        incrementImageLabelKey(1);
    }, [incrementImageLabelKey]);
    useHotkeys('ctrl+enter', (e) => {
        e.preventDefault();
        addNewImageLabelData();
    }, [addNewImageLabelData]);
    useHotkeys('enter', (e) => {
        e.preventDefault();
        activeCaptionTextboxRef.current?.focus();
    }, []);
    useHotkeys('del', (e) => {
        e.preventDefault();
        console.log(`Remove image ${imageLabelKey}`);
        deleteImageLabelData(imageLabelKey);
    }, [imageLabelKey, deleteImageLabelData]);
    useHotkeys('1', () => {
        handleSquareCrop(imageLabelKey);
    }, [imageLabelKey, handleSquareCrop]);
    useHotkeys('2', () => {
        handleCenterCrop(imageLabelKey);
    }, [imageLabelKey, handleCenterCrop]);
    useHotkeys('3', () => {
        handleFitCrop(imageLabelKey);
    }, [imageLabelKey, handleFitCrop]);
    useHotkeys('4', () => {
        handleFillCrop(imageLabelKey);
    }, [imageLabelKey, handleFillCrop]);

    return (
        <RequireAuth>
        {
            (taskId && taskData && !isLoading) ?
            <div 
                tabIndex={0}
                className='w-full flex justify-center text-base'
                onKeyDown={(e) => {
                    if (e.shiftKey && !isShiftDownRef.current) {
                        isShiftDownRef.current = true;
                        if (crop) {
                            const {width, height} = crop;
                            if (width && height) {
                                setCropAspect(width / height);
                            }
                        }
                    }
                }}
                onKeyUp={(e) => {
                    if (isShiftDownRef.current) {
                        isShiftDownRef.current = false;
                        setCropAspect(undefined);
                    }
                }}
            >
                <div className='w-full flex flex-row justify-center items-center'>
                    <div className='flex-1 h-screen overflow-y-auto'>
                        <div className='grow min-h-full flex justify-center items-center'>
                            <ReactCrop 
                                crop={crop}
                                aspect={cropAspect}
                                onChange={(pixelCrop: PixelCrop, percentCrop: PercentCrop) => setImageCrop({
                                    crop: percentCrop,
                                    imageId: taskDataId,
                                    imageLabelKey,
                                    setImageLabelDataset,
                                })}
                            >
                                <img alt="crop-subject" src={imageSrc} onLoad={onImageLoad}/>
                            </ReactCrop>
                        </div>
                    </div>
                    <div className='min-w-[380px] flex-none py-6 flex flex-col h-screen border-l border-slate-200'>
                        <div className='px-6 pb-6 border-b font-semibold text-left'>
                            <div className='flex flex-row items-center'>
                                <div className='flex-1'>
                                    Image <input 
                                        type="number"
                                        className='max-w-[100px] mx-2 p-2 focus-visible:outline-none border border-slate-200 focus:border-slate-300 rounded-md bg-slate-200/50' 
                                        value={imageInputIndex} 
                                        onChange={e => setImageInputIndex(e.currentTarget?.value)} 
                                        onBlur={e => setImageIndex(parseInt(e.currentTarget?.value))}/> / {Math.max(taskDataIds.length - 1, 0)} 
                                </div>
                                <div
                                    className={`${buttonClassName} bg-red-500 hover:bg-red-600 my-0`}
                                    onClick={() => {
                                        // Remove the label
                                        handleDeleteTaskData(taskId, taskDataId);
                                    }}
                                >
                                    Delete
                                </div>
                                {/* <Link to={`/labeled/${taskId}`} className={`${buttonClassName} my-0`}>
                                    Labeled
                                </Link> */}
                            </div>
                        </div>
                        <div className='grow px-6 w-full overflow-x-hidden overflow-y-auto'>
                            <div 
                                className='flex flex-col mt-4'
                            >
                                <div className='w-full text-left font-semibold'>Navigate</div>
                                <div className='flex flex-row justify-center items-center'>
                                    <div 
                                        className={`grow flex flex-row justify-center items-center mr-2 ${buttonClassName}`}
                                        onClick={() => incrementImageIndex(-1)}
                                    >
                                        <span>
                                            Prev
                                        </span>
                                        <div className={hotkeyBlueClassName}>
                                            left
                                        </div>
                                    </div>
                                    <div 
                                        className={`grow flex flex-row justify-center items-center ${buttonClassName}`}
                                        onClick={() => incrementImageIndex(1)}
                                    >
                                        <span>
                                            Next
                                        </span>
                                        <div className={hotkeyBlueClassName}>
                                            right
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                className='flex flex-col'
                            >
                                <div className='w-full text-left font-semibold'>Crop</div>
                                <div className="grid grid-cols-2 gap-2 mt-5 mb-8">
                                    <div
                                        className={`${flexRowCenter} ${buttonClassName} md:m-0`}
                                        onClick={() => {
                                            handleSquareCrop(imageLabelKey);
                                        }}
                                    >
                                        Square
                                        <div className={`${hotkeyBlueClassName} px-4`}>1</div>
                                    </div>
                                    <div
                                        className={`${flexRowCenter} ${buttonClassName} md:m-0`}
                                        onClick={() => {
                                            handleCenterCrop(imageLabelKey);
                                        }}
                                    >
                                        Center
                                        <div className={`${hotkeyBlueClassName} px-4`}>2</div>
                                    </div>
                                    <div
                                        className={`${flexRowCenter} ${buttonClassName} md:m-0`}
                                        onClick={() => {
                                            handleFitCrop(imageLabelKey);
                                        }}
                                    >
                                        Fit
                                        <div className={`${hotkeyBlueClassName} px-4`}>3</div>
                                    </div>
                                    <div
                                        className={`${flexRowCenter} ${buttonClassName} md:m-0`}
                                        onClick={() => {
                                            handleFillCrop(imageLabelKey);
                                        }}
                                    >
                                        Fill
                                        <div className={`${hotkeyBlueClassName} px-4`}>4</div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex-1 flex flex-col overflow-x-hidden overflow-y-auto'>
                                {Object.entries(imageLabelDataset?.[taskDataId]?.record || {}).map(([key, imageLabel]) => {
                                    const isActive = key === imageLabelKey;
                                    return imageLabel && (
                                        <div
                                            ref={(element) => {
                                                if (isActive) {
                                                    activeImageLabelElementRef.current = element;
                                                    setTimeout(() => {
                                                        element?.scrollIntoView({
                                                            behavior: "smooth",
                                                            block: "start",
                                                        });
                                                    }, 100);
                                                }
                                            }}
                                            key={key}
                                            className={`p-4 mb-4 border-2 ${!(imageLabel.caption) ? (isActive ? 'border-red-500 shadow-lg shadow-red-500/20' : 'border-red-200') : (isActive ? 'border-blue-300 shadow-lg shadow-blue-500/20' : 'border-slate-200')} rounded-md text-left`}
                                            onClick={() => {
                                                setImageLabelKey(key);
                                            }}
                                        >
                                            <div className='flex flex-row mb-2 truncate'>
                                                <div className='grow font-semibold'>Caption  <span className='text-slate-300 font-text truncate'>{key}</span></div>
                                            </div>
                                            <CaptionTextBox
                                                key={key}
                                                ref={(element) => {
                                                    if (isActive) {
                                                        activeCaptionTextboxRef.current = element;
                                                    }
                                                }}
                                                imageId={taskDataId}
                                                imageLabel={imageLabel}
                                                imageLabelKey={key}
                                                setImageLabelKey={setImageLabelKey}
                                                setImageLabelDataset={setImageLabelDataset}
                                                onKeyDown={(e) => {
                                                    const code = e.code?.toLowerCase();
                                                    if (code === 'enter' || code === 'escape') {
                                                        activeCaptionTextboxRef.current?.blur();
                                                    }
                                                }}
                                            />
                                            <div 
                                                className='w-full grid grid-cols-2 gap-2'
                                            >
                                                <div 
                                                    className='flex flex-row justify-center items-center select-none cursor-pointer bg-red-500 hover:bg-red-600 p-2 rounded-md text-white'
                                                    onClick={() => {
                                                        deleteImageLabelData(key);
                                                    }}
                                                >
                                                    <span className='my-2'>
                                                        Delete
                                                    </span>
                                                    {isActive && <div className={hotkeyRedClassName}>
                                                        del
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div 
                                className='flex flex-row justify-center items-center bg-blue-500 hover:bg-blue-600 rounded-md p-2 text-white select-none cursor-pointer'
                                onClick={() => {
                                    const imageLabelKey = addImageLabelData({
                                        imageId: taskDataId,
                                        setImageLabelDataset,
                                    });
                                    setImageLabelKey(imageLabelKey);
                                }}
                            >
                                <span>
                                    Add More
                                </span>
                                <div className={hotkeyBlueClassName}>
                                    ctrl + enter
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
            <LoadingScreen/>
        }
        </RequireAuth>
    );
}