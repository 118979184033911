import AWS from 'aws-sdk';

let configInitialized = false;

export function initAwsConfig() {
    if (!configInitialized) {
        configInitialized = true;
        console.log('Load AWS config');
    
        AWS.config.region = 'us-east-1'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-east-1:d2db0612-9ab2-41cb-96aa-b1fc2e08ed9d',
        });
    }
}